import React, { Dispatch, FC, SetStateAction } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Block, PopupDataAction } from "../../App";

import classes from "./GridBlock.module.css";

interface Props extends Block {
  setPopupData: Dispatch<SetStateAction<PopupDataAction>>;
  setShowPopup: Dispatch<SetStateAction<boolean>>;
}

interface ContentProps extends Block {}

const BlockContent: FC<ContentProps> = ({
  backgroundColor = "black",
  title,
  excerpt,
  imageSize,
  image,
  type,
  hideTitle,
}) => {
  let tag = "";
  switch (type) {
    case "news":
      tag = "News";
      break;
    case "partners":
      tag = "Partner";
      break;
    case "characters":
      tag = "Character";
      break;
    case "informers":
      tag = "Informer";
      break;
    case "coins":
      tag = "Coins";
      break;
    case "nft":
      tag = "NFT";
      break;
    case "collections":
      tag = "Collections";
      break;
    case "series":
      tag = "Series";
      break;
  }

  return (
    <div className={classes.content}>
      <div className={classes.bg} style={{ backgroundColor: backgroundColor }}>
        {image && (
          <LazyLoadImage
            alt={image.alt || title}
            height={image.height}
            src={image.src}
            width={image.width}
            className={imageSize === "cover" ? classes.cover : ""}
          />
        )}
        {tag && <div className={classes.tag}>{`#${tag}`}</div>}
      </div>
      {((title && !hideTitle) || excerpt) && (
        <div className={classes.bottom}>
          {title && !hideTitle && <h2>{title}</h2>}
          {excerpt && <p>{excerpt}</p>}
        </div>
      )}
    </div>
  );
};

const GridBlock: FC<Props> = ({
  type,
  slug,
  backgroundColor = "black",
  title,
  excerpt,
  image,
  imageSize = "contain",
  link,
  hideTitle = false,
  date,
  leadVisualImage,
  setPopupData,
  setShowPopup,
}) => {
  const blockClickHandler = () => {
    switch (type) {
      case "news":
      case "partners":
      case "characters":
      case "informers":
      case "coins":
      case "nft":
      case "social":
      case "collections":
      case "series":
        setPopupData({
          type,
          slug,
          title: title || "",
          date,
          leadVisualImage,
        });
        setShowPopup(true);
        window.location.hash = slug;
        break;
    }
  };
  return link ? (
    link.startsWith("http") ? (
      <a href={link} target="_blank" rel="noreferrer" className={classes.block}>
        <BlockContent
          slug={slug}
          type={type}
          backgroundColor={backgroundColor}
          title={title}
          excerpt={excerpt}
          image={image}
          imageSize={imageSize}
          hideTitle={hideTitle}
        />
      </a>
    ) : (
      <button onClick={blockClickHandler} className={classes.block}>
        <BlockContent
          slug={slug}
          type={type}
          backgroundColor={backgroundColor}
          title={title}
          excerpt={excerpt}
          image={image}
          imageSize={imageSize}
          hideTitle={hideTitle}
        />
      </button>
    )
  ) : (
    <div className={classes.block}>
      <BlockContent
        type={type}
        slug={slug}
        backgroundColor={backgroundColor}
        title={title}
        excerpt={excerpt}
        image={image}
        imageSize={imageSize}
        hideTitle={hideTitle}
      />
    </div>
  );
};

export default React.memo(GridBlock);
